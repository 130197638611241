import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './home.js';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { React, useState, createContext } from 'react';
import Upload_fiche from './Upload_fiche';
import Upload_cv from './Upload_cv';


function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;