import { React, useState, createContext } from 'react';
import logo from './ST_BLANC_GRADIENT.png'
import { SignedIn, SignedOut, SignInButton, SignOutButton, useUser } from '@clerk/clerk-react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Upload_fiche from './Upload_fiche';
import Upload_cv from './Upload_cv';

const initialState = {
  tasks: {}
};

export const FicheContext = createContext(initialState);

// Create a Context Provider
const FicheContextProvider = ({ children }) => {
  const [fiche_js, setFicheJS] = useState(initialState);

  return (
      <FicheContext.Provider value={{ fiche_js, setFicheJS }}>
          {children}
      </FicheContext.Provider>
  );
};

const Home = (props) => {
  // Use the useUser hook to get the details about the logged in user
  const { user } = useUser()

  return (
    <div className="mainContainer">
      {/* The children of the SignedOut component are rendered only when the user is signed out from the app. In this case, the app will render a SignInButton */}
      <SignedOut>
        <div className={'titleContainer'}>
          <div><img src={logo} alt="Logo" className='App-logo' /></div>
        </div>
        <div>Classez les CV en fonction d'une offre d'emploi.</div>
        <br/>
        <SignInButton>
          <input className={'btn-degrad'} type="button" value={'Connexion'} />
        </SignInButton>
      </SignedOut>

      {/* The children of the SignedIn component are rendered only when the user is signed in. In this case, the app will render the SignOutButton */}
      <SignedIn>
        <Row>
          <Col sm={2} className='justify-content-start'>
            <img src={logo} alt="Logo" className='App-logo_header' />
          </Col>
          <Col sm={8}></Col>
          <Col sm={2} >
            <SignOutButton>
              <input className={'btn-degrad-header'} type="button" value={'Déconnecter'} />
            </SignOutButton>
            {user ? <div>{user.primaryEmailAddress.emailAddress}</div> : null}
          </Col>
        </Row>

        <FicheContextProvider>
          <Row>
            <Col>
              <Upload_fiche />
            </Col>
            <Col>
            <Upload_cv />
            </Col>
          </Row>
        </FicheContextProvider>
        
      </SignedIn>

    </div>
  )
}

export default Home