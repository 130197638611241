import React, { useState, useContext } from "react";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FicheContext } from "./home";

const Upload_fiche = () => {
  const [file, setFile] = useState();
  const [status, setStatus] = useState("initial");
  const [fiches, setFiche] = useState([]);
  const {setFicheJS} = useContext(FicheContext);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setStatus("initial");
      setFile(e.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (file) {
      setStatus("uploading");

      const formData = new FormData();
      formData.append("file", file);

      try {
          await fetch("https://api.smarttalent.fr:8443/convert_fiche", {
          method: "POST",
          body: formData,
        }).then((data) => {
        return data.json();
      }).then((data) => {
           setFiche(data);
           setFicheJS(data);
      });

        setStatus("success");
      } catch (error) {
        console.error(error);
        setStatus("fail");
      }
    }
  };

  return (
    <>
      <Container className='formUpload'>
        <Row>
          <Col>
              <Form.Group controlId="file" className="mb-3">
                  <Form.Label>1. Téléchargez une fiche de poste</Form.Label>
                  <Form.Control type="file" onChange={handleFileChange} />
              </Form.Group>
          </Col>


          {file && (
            <Button variant="primary" type="submit" className='btn-degrad' onClick={handleUpload} >
              Envoyer le fichier
            </Button>
          )}

          <Result status={status} />
        </Row>
      </Container>
      {
        fiches && (
          <Row>
            <Col>
              <ListGroup as="ol" >
                <ListGroup.Item as="li">
                    <b>{ fiches.Poste_recherche }</b>
                    <br /><br />
                    { fiches.Competences }
                    <br /><br />
                    { fiches.Diplomes }
                    <br /><br />
                    { fiches.Langue }
                    <br /><br />
                    { fiches.Soft_skills }
                    <br /><br />
                    { fiches.Task_de_ProfessionalExperience }
                    <br /><br />
                    { fiches.description_poste }
                    <br />
                  </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        )
      }

    </>
  );
};


const Result = ({ status }) => {
  if (status === "success") {
    return <p>

    </p>;
  } else if (status === "fail") {
    return <p>❌ Echec lors de l'envoi du fichier!</p>;
  } else if (status === "uploading") {
    return <p>⏳ Analyse en cours, veuillez patienter...</p>;
  } else {
    return null;
  }
};

export default Upload_fiche;