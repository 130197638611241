import React, { useState, useContext } from "react";
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { FicheContext } from "./home";

const Upload_cv = () => {
  const [files, setFiles] = useState();
  const [status, setStatus] = useState("initial");
  const [cvs, setCV] = useState([]);
  const [compare, setCompare] = useState([]);
  const { fiche_js } = useContext(FicheContext);

  const handleFileChange = (e) => {
    if (e.target.files) {
      setStatus("initial");
      setFiles(e.target.files);
    }
  };

  const handleUpload = async () => {
    if (files) {
      
      for(let file of files){

        setStatus("uploading");

        try {
          var formData = new FormData();
          formData.append("file", file);

          await fetch("https://api.smarttalent.fr:8443/convert_cv", {
          method: "POST",
          body: formData,
          }).then((data) => {
          return data.json();
        }).then((data) => {
            //setCV(data);
            cvs.push(data);

        });
    
          setStatus("success");
        } catch (error) {
          console.error(error);
          setStatus("fail");
        }
      }

    }

    // compare
    setStatus("analyse");

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({ "fiche_poste" : fiche_js, "cvs" : cvs});

      await fetch("https://api.smarttalent.fr:8443/compare_fiche_with_cvs", {
        method: "POST",
        headers: myHeaders,
        body: raw,
        }).then((data) => {
        return data.json();
      }).then((data) => {
          setCompare(data);
          setStatus("success");
      });

    }catch (error) {
      console.error(error);
      setStatus("fail");
    }
  };

  return (
    <>
      <Container className='formUpload'>
        <Row>
          <Col>
              <Form.Group controlId="file" className="mb-3">
                  <Form.Label>2. Téléchargez plusieurs CV</Form.Label>
                  <Form.Control type="file" multiple onChange={handleFileChange} />
              </Form.Group>
          </Col>

      {files && (
        <Button variant="primary" type="submit" className='btn-degrad' onClick={handleUpload}>
          Envoyer {files.length > 1 ? "fichiers" : "le fichier"}
        </Button>
      )}

      <Result status={status} />

        </Row>
      </Container>

      <Row>
        <Col>
          <ListGroup as="ol" numbered>
            {compare.map((cv, i) => (
                <ListGroup.Item as="li" key={i}>
                    <b>{ cv.nom_prenom }</b> (Score total: <b>{ Math.round(cv.total_score) }</b> )
                    <br /><br />
                    <b>Telephone:</b> { cv.numero_telephone }
                    <br />
                    <b>Mail:</b> { cv.e_mail }
                    <br />
                    <b>Lieu:</b> { cv.ville_pays }
                    <br /><br />
                    <b>Score détaillé</b>
                    <Row className="row-full">
                      <Col>
                        <b>Competences: </b> { Math.round(cv.individual_scores.Competences) }
                      </Col>
                      <Col>
                        <b>Diplomes: </b> { Math.round(cv.individual_scores.Diplomes) }
                      </Col>
                    </Row>
                    <Row className="row-full">
                      <Col>
                        <b>Soft skills: </b> { Math.round(cv.individual_scores.Soft_skills) }
                      </Col>
                      <Col>
                        <b>Langue: </b> { Math.round(cv.individual_scores.Langue) }
                      </Col>
                    </Row>
                    <Row className="row-full">
                      <Col>
                        <b>Expérience: </b> { Math.round(cv.individual_scores.Task_de_ProfessionalExperience) }
                      </Col>
                      <Col>
                        <b>Poste: </b> { Math.round(cv.individual_scores.Poste_recherche) }
                      </Col>
                    </Row>
                  </ListGroup.Item>     
            ))}
          </ListGroup>
        </Col>
      </Row>
    </>
  );
};


const Result = ({ status }) => {
  if (status === "success") {
    return <p>⏳ Retranscription en cours, veuillez patienter...</p>;
  } else if (status === "fail") {
    return <p>❌ Echec lors de l'envoi du fichier!</p>;
  } else if (status === "uploading") {
    return <p>⏳ Analyse PDF en cours, veuillez patienter...</p>;
  } else if (status === "analyse") {
    return <p>⏳ Comparatif en cours, veuillez patienter...</p>;
  } else {
    return null;
  }
};

export default Upload_cv;